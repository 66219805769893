
import {useSiteDataStore} from '~/store/siteDataStore';

const FB = 'en-US';

export const useTranslation = (category: string = 'site') => {
    const {translations} = useSiteDataStore();
    const {activeSite} = useMultisite();

    return (key: string, locale: string = toValue(activeSite)?.locale) =>
        toValue(translations)?.[locale]?.[category]?.[key] ??
        toValue(translations)?.[FB]?.[category]?.[key] ??
        key;

};
